import React from 'react'
import styled from 'styled-components'
import useTranslation from '@hooks/useTranslation'

const MainContainer = styled.div`
  background-color: ${props => props.theme.color.brightOrange};
  display: flex;
  justify-content: center;
`

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin: 20px;
`

const NumberLabel = styled.div`
  border-radius: 50%;
  border: 3px ${props => props.theme.color.white} solid;
  font-size: 1.5em;
  font-weight: bold;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
`

const AnalyzeCreatorAccounts = () => {
  const { labelStrings } = useTranslation()

  return (
    <MainContainer>
      <NumberWrapper>
        <NumberContainer>
          <NumberLabel>1</NumberLabel>
          {labelStrings.findAnalyzeCreatorAcc}
        </NumberContainer>
        <NumberContainer>
          <NumberLabel>2</NumberLabel>
          {labelStrings.saveManageCreatorContacts}
        </NumberContainer>
      </NumberWrapper>
    </MainContainer>
  )
}

export default AnalyzeCreatorAccounts
