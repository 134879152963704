import React from 'react'
import LandingLayout from '@layouts/LandingLayout'
import styled from 'styled-components'
// import MainPerformanceContainer from '@containers/MainPerformanceContainer'
import InfluencerDBContainer from '@containers/InfluencerDBContainer'
import AnalyzeCreatorAccounts from '@containers/AnalyzeCreatorAccounts'
import InfluencerDBLogosContainer from '@containers/InfluencerDBLogosContainer'

const MainPerformanceSectionGroup = styled.div`
  background: ${props => props.theme.bgGradientMainSection};
`

const InfluencerDBPageContainer = () => {
  return (
    <LandingLayout>
      <InfluencerDBContainer />
      {/* <MainPerformanceSectionGroup>
        <MainPerformanceContainer />
      </MainPerformanceSectionGroup> */}
      <AnalyzeCreatorAccounts />
      <InfluencerDBLogosContainer />
    </LandingLayout>
  )
}

export default InfluencerDBPageContainer
