import React from 'react'
import styled from 'styled-components'
import { InfludataLogoIcon } from '@common/icons'
import useTranslation from '@hooks/useTranslation'

const MainContainer = styled.div`
  background-color: ${props => props.theme.color.brightOrange};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Partnership = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`

const LogosContainerText = styled.div`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
  max-width: 70%;
`

const InfludataLogoContainer = styled.div`
  padding: 15px;
`

const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  margin: 30px 0;
`

const InfluencerDBLogo = styled.div`
  width: 160px;
  height: 80px;
  background-image: url('../../images/idblogo.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 30px;
`

const XMark = styled.div`
  font-size: 40px;
`

const InfluencerDBLogosContainer = () => {
  const { labelStrings } = useTranslation()

  return (
    <MainContainer>
      <LogosContainer>
        <InfluencerDBLogo />
        <XMark>X</XMark>
        <InfludataLogoContainer>
          <InfludataLogoIcon />
        </InfludataLogoContainer>
      </LogosContainer>
      <Partnership>{labelStrings.benefitPartnership}</Partnership>
      <LogosContainerText>{labelStrings.freeInfludataTwoWeeks}</LogosContainerText>
    </MainContainer>
  )
}

export default InfluencerDBLogosContainer
