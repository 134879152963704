import React from 'react'
import styled from 'styled-components'
import useTranslation from '@hooks/useTranslation'

const MainContainer = styled.div`
  background: ${props => props.theme.color.orange};
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InfluencerDBText = styled.div`
  font-size: 2em;
  font-weight: bold;
  font-style: italic;
  flex: 3;
  padding: 0 100px;
`

const LogoAvatarContainer = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 180px;
`

const AvatarTitle = styled.div`
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
`

const Avatar = styled.div`
  border-radius: 50%;
  border: 5px white solid;
  width: 150px;
  min-width: 150px;
  height: 150px;
  background-image: url('../../images/InfluencerDB_Levenhagen_Robert.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

const Logo = styled.div`
  width: 210px;
  height: 100px;
  background-image: url('../../images/idblogo.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 30px;
`

const InfluencerDBContainer = () => {
  const { labelStrings } = useTranslation()

  return (
    <MainContainer>
      <InfluencerDBText>{labelStrings.influencerDBData}</InfluencerDBText>
      <LogoAvatarContainer>
        <AvatarContainer>
          <Avatar />
          <AvatarTitle>Robert Levenhagen {labelStrings.influencerDB_SEO}</AvatarTitle>
        </AvatarContainer>
        <Logo />
      </LogoAvatarContainer>
    </MainContainer>
  )
}

export default InfluencerDBContainer
