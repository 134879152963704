import React from 'react'
import availableLangs from '@localization/availableLangs'
import InfluencerDBPageContainer from '@containers/pagesContainers/InfluencerDBPageContainer'
import SeoContainer from '@containers/SeoContainer'

const InfluencerDB = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.en} pagePath='influencerdb' />
      {/* <InfluencerDBPageContainer /> */}
    </>
  )
}

export default InfluencerDB
